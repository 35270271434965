import Typography2 from '@c/Typographies/Typography2';
import React from 'react';
import { Notifications } from 'store/reducers/notifications.reducer';
import styles from './styles.module.scss';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { DateTime } from 'luxon';
import { theme } from 'theme/theme';

type NotificationComponentProps = {
  notification: Notifications;
};

const NotificationComponentCompact: React.FC<NotificationComponentProps> = ({
  notification,
}) => {
  const icons = {
    warning: (
      <WarningIcon
        color="warning"
        style={{ width: '32px', height: '32px', marginBottom: '-6px' }}
      />
    ),
    error: (
      <ErrorIcon
        color="error"
        style={{ width: '32px', height: '32px', marginBottom: '-6px' }}
      />
    ),
    success: (
      <CheckCircleIcon
        color="success"
        style={{ width: '32px', height: '32px', marginBottom: '-6px' }}
      />
    ),
    info: (
      <InfoIcon
        color="info"
        style={{ width: '32px', height: '32px', marginBottom: '-6px' }}
      />
    ),
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.notif_header}>
          <div>{icons[notification.type]}</div>
          <div>
            <Typography2 size="m" weight="500">
              {notification.title}
            </Typography2>
            <Typography2 size="m" weight="400" className={styles.message}>
              {notification?.message}
            </Typography2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationComponentCompact;
