import NotificationsFeed from '@c/Settings/SettingsAccountComponent/NotificationsFeed';
import NotificationComponentCompact from '@c/Settings/SettingsAccountComponent/NotificationsFeed/NotificationComponentCompact';
import Typography2 from '@c/Typographies/Typography2';
import { Popover } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { theme } from 'theme/theme';
import styles from './styles.module.scss';

type NotificationsFeedPopoverProps = {
  anchorEl?: any;
  onClose: () => void;
};

const NotificationsFeedPopover: React.FC<NotificationsFeedPopoverProps> = ({
  anchorEl,
  onClose,
}) => {
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications,
  );
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ horizontal: -340, vertical: 55 }}
      sx={{ padding: 0, margin: 0, overflow: 'visible!important' }}
      classes={{
        root: styles.popover_root,
        paper: styles.popover_root,
      }}
      disableScrollLock
    >
      <Box
        sx={{
          padding: '12px',
          maxHeight: '400px',
          padding: 0,
          position: 'relative',
          overflow: 'visible!important',
        }}
      >
        <div
          style={{
            width: '1px',
            height: '1px',
            top: '-30px',
            right: '7px',
            position: 'absolute',
            border: '15px solid transparent',
            borderBottom: '15px solid white',
          }}
        />
        {notifications?.length === 0 && (
          <div
            style={{
              padding: '12px 12px',
              width: '350px',
              textAlign: 'center',
            }}
          >
            <Typography2
              weight="400"
              size="m"
              color={theme.palette.textTransluent.main}
            >
              У вас пока нет уведомлений
            </Typography2>
          </div>
        )}
        {notifications?.[0] && (
          <NotificationComponentCompact notification={notifications?.[0]} />
        )}
        {notifications?.[1] && (
          <NotificationComponentCompact notification={notifications?.[1]} />
        )}
        <div className={styles.show_more_container}>
          <a href="/profile/notifications">
            <Typography2
              weight="400"
              size="m"
              color={theme.palette.primary.main}
              className={styles.link}
            >
              Показать Все
            </Typography2>
          </a>
        </div>
      </Box>
    </Popover>
  );
};

export default NotificationsFeedPopover;
