import React, { useContext, useRef, useState } from 'react';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import Typography2 from '@c/Typographies/Typography2';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, CircularProgress, TextField } from '@mui/material';
import globalStyles from '../styles.module.scss';
import EmailIcon from '@mui/icons-material/Email';
import styles from './styles.module.scss';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { theme } from 'theme/theme';
import { NotificationContext } from '@c/notification/NotificationProvider';
import { NotificationType } from 'shared/interfaces/notification.interface';
import { requestResetPassword } from 'api/user.service';

type ForgotPasswordStepProps = {
  onPasswordResetBack: () => void;
};

const ForgotPasswordStep: React.FC<ForgotPasswordStepProps> = ({
  onPasswordResetBack,
}) => {
  const { handleSubmit, register } = useForm();
  const [loading, setLoading] = useState(false);
  const notify = useContext(NotificationContext);

  const onSubmit = async (e) => {
    setLoading(true);
    try {
      await requestResetPassword(e.identifier);
      notify(
        NotificationType.Success,
        'Ссылка для восстановления пароля отправлена на почту',
      );
    } catch {
      // notify(NotificationType.Error, 'Не получилось запросить сброс пароля');
    } finally {
      setLoading(false);
    }
    // onPasswordReset();
  };

  const formRef = useRef(null);

  const submitProgramatically = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      className={globalStyles.form_container}
    >
      <Typography2
        weight="400"
        size="m"
        className={styles.back_row}
        color={theme.palette.primary.main}
        onClick={onPasswordResetBack}
      >
        <ChevronLeftIcon color="primary" />
        Назад
      </Typography2>
      <Typography2 weight="600" size="xl" className={styles.heading}>
        Восстановление Пароля
      </Typography2>
      <TextField
        {...register('identifier', {
          required: 'Пожалуйста, заполните имя пользователя или почту',
        })}
        size="small"
        aria-label="identifier"
        placeholder="Логин или email"
        fullWidth
        InputProps={{
          startAdornment: (
            <EmailIcon className={styles.icon} color="textTransluent" />
          ),
        }}
      />

      <Button variant="contained" type="submit" fullWidth size="large">
        {loading ? (
          <CircularProgress size={26} color="secondary" />
        ) : (
          'Восстановить'
        )}
      </Button>
      <div className={styles.bottom}>
        <Typography2
          weight="400"
          size="m"
          color={theme.palette.textBold.main}
          className={styles.text}
        >
          Не получили письмо?{' '}
        </Typography2>
        <Typography2
          weight="400"
          size="m"
          onClick={onPasswordResetBack}
          color={theme.palette.primary.main}
          className={styles.link}
          onClick={submitProgramatically}
        >
          Отправить ещё раз
        </Typography2>
      </div>
    </form>
  );
};

export default ForgotPasswordStep;
