import React, { useContext, useRef, useState } from 'react';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import Typography2 from '@c/Typographies/Typography2';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, CircularProgress, TextField } from '@mui/material';
import globalStyles from '../styles.module.scss';
import EmailIcon from '@mui/icons-material/Email';
import styles from './styles.module.scss';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { theme } from 'theme/theme';
import { NotificationType } from 'shared/interfaces/notification.interface';
import { resendCode, tryApproveCode } from 'api/user.service';
import { useDispatch } from 'react-redux';
import { getUserInfo, toggleAuthModal } from 'store/actions/user.actions';
import { NotificationContext } from '@c/notification/NotificationProvider';
import { useRouter } from 'next/router';

type EmailVerificationStepProps = {
  onEmailVerified: () => void;
};

const EmailVerificationStep: React.FC<EmailVerificationStepProps> = ({
  onEmailVerified,
}) => {
  const { handleSubmit, register, formState } = useForm();
  const [loading, setLoading] = useState(false);
  const { errors } = formState;
  const dispatch = useDispatch();
  const router = useRouter();
  const notify = useContext(NotificationContext);
  const onSubmit = async (e) => {
    setLoading(true);
    try {
      await tryApproveCode(e.code);
      await dispatch(getUserInfo());
      dispatch(toggleAuthModal());
      if (router.query?.redirect) {
        router.push(router?.query?.redirect);
      }
      onEmailVerified();
      // notify(NotificationType.Success, 'Код подтвержден');
    } catch {
      notify(NotificationType.Error, 'Не получилось подтвердить код');
    } finally {
      setLoading(false);
    }
  };

  const formRef = useRef(null);
  const handleResendCode = async () => {
    setLoading(true);
    try {
      await resendCode();
    } catch (e) {
      if (e.response.status === 400) {
        notify(NotificationType.Error, 'Ошибка', 'Код уже был выслан повторно');
      }
    } finally {
      setLoading(false);
    }
  };

  const [showResend, setShowResend] = useState(false);

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      className={globalStyles.form_container}
    >
      <Typography2 weight="600" size="xl" className={styles.heading}>
        Подтвердите Почту
      </Typography2>
      <Typography2 weight="400" size="m" className={styles.heading}>
        Введите код, отправленный вам на почту
      </Typography2>
      <TextField
        {...register('code', {
          required: 'Пожалуйста, введите код',
          minLength: {
            value: 3,
            message: 'Пожалуйста, введите правильный код',
          },
        })}
        size="small"
        aria-label="code"
        placeholder="Код"
        fullWidth
        error={!!errors.code}
        helperText={errors?.code?.message}
        inputProps={{ min: 0, style: { textAlign: 'center' }, maxLength: 4 }}
      />

      <Button
        variant="contained"
        disabled={loading}
        type="submit"
        fullWidth
        size="large"
      >
        {loading ? (
          <CircularProgress size={26} color="secondary" />
        ) : (
          'Подтвердить'
        )}
      </Button>
      <div style={{ textAlign: 'center' }} onClick={handleResendCode}>
        <Typography2 className={styles.link} color={theme.palette.primary.main}>
          Не получили письмо? Отправить ещё раз
        </Typography2>
      </div>
    </form>
  );
};

export default EmailVerificationStep;
