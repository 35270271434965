import SearchField from '@c/Inputs/SearchField';
import { Button, IconButton } from '@mui/material';
import Image from 'next/image';
import React, { useContext, useEffect, useState } from 'react';
import { theme } from 'theme/theme';
import { isUserLoggedIn, useBaseUrl } from 'utils/user.utils';
import HeaderUserPreview from '../HeaderUserPreview';
import styles from './header_redesigned.module.css';
import { useRouter } from 'next/router';
import Person2Icon from '@mui/icons-material/Person';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch } from 'react-redux';
import { toggleAuthModal } from 'store/actions/user.actions';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import UserAuthModal from '@c/Dialogs/AuthModalRedesign';
import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import MobileDrawer from './MobileDrawer';
import SearchIcon from '@mui/icons-material/Search';
import Link from 'next/link';
import { SlugContext } from '@c/GirlComponents/SlugContext';
import { getUserTown } from '../../../utils/user.utils';
import { latinize } from '../../../shared/search.utils';

type HeaderRedesignedProps = {
  onSearch?: (arg1: string) => void;
  onSubmit?: () => void;
};

const HeaderRedesigned: React.FC<HeaderRedesignedProps> = (props) => {
  const { onSearch, onSubmit } = props;
  const [search, setSearch] = useState(false);
  const [focus, setFocus] = useState(true);
  const isLoggedIn = isUserLoggedIn();
  const router = useRouter();
  useEffect(() => {
    if (!focus) {
      setSearch(false);
    }
  }, [focus]);
  const slug = useContext(SlugContext);

  const handleOnSubmit = (e) => {
    setFocus(false);
    const basePath = slug?.getBasePath();
    // const userTown = getUserTown();
    // if (!userTown) {
    router.push({
      pathname: '/girls',
      query: { sq: e },
    });
    // } else {
    // router.push({
    // pathname: `/girls/all/${latinize(userTown)}`,
    // query: { sq: e },
    // });
    // }

    if (onSubmit) {
      onSubmit();
    }
  };

  const [searchQuery, setSearchQuery] = useState(null);

  const handleOnSearch = (e) => {
    if (onSearch) {
      onSearch(e);
    }
    setSearchQuery(e);
  };

  const dispatch: AppDispatch = useDispatch();

  const [cityDropdownElement, setCityDropdownElement] = useState(null);

  const handleOpenDropdown = (e) => {
    setCityDropdownElement(e.target);
  };

  const handleCloseDropdown = () => {
    setCityDropdownElement(null);
  };

  const handleToggleAuth = () => {
    dispatch(toggleAuthModal());
  };

  const { getRedirectLink } = useBaseUrl();

  const showAuthModal = useSelector(
    (state: RootState) => state.user.showAuthModal,
  );
  const handleCreateNew = () => {
    const redirectUrl = '/profile/managing';
    if (!isLoggedIn && !showAuthModal) {
      dispatch(toggleAuthModal());
    }
    router.push({
      pathname: router.pathname,
      query: { ...router.query, redirect: redirectUrl },
    });
  };
  const [drawer, setDrawer] = useState(false);
  return (
    <div
      className={styles.header_container}
      style={{ backgroundColor: theme.palette.lightPurple.main }}
    >
      <UserAuthModal isLoggedIn={isLoggedIn} />
      <MobileDrawer open={drawer} onClose={() => setDrawer(false)} />
      <IconButton
        className={styles.burger_menu_button}
        onClick={() => setDrawer(true)}
      >
        <MenuIcon color="secondary" />
      </IconButton>
      <Link href="/" className={styles.image_nav}>
        <div className={styles.image_container}>
          <Image
            fill
            style={{
              objectFit: 'contain',
            }}
            alt="Logo"
            src="/logo_header.svg"
          />
        </div>
      </Link>
      <div className={styles.image_nav_small}>
        <Link
          href="/"
          style={{
            height: '16px',
            width: '48px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Image
            style={{
              objectFit: 'contain',
              // marginTop: '-16px',
            }}
            width={48}
            height={48}
            alt="Logo"
            src="/logo_small_white.svg"
          />
        </Link>
      </div>
      <nav className={styles.nav}>
        {/* <Link href="/" className={styles.image_nav_small}>
          <div className={styles.image_container}></div>
        </Link> */}

        {search && (
          <div className={styles.searchFieldContainer}>
            <SearchField
              setFocus={setFocus}
              onSearch={handleOnSearch}
              onSubmit={handleOnSubmit}
            />
          </div>
        )}

        <div onClick={() => setSearch(true)} style={{ position: 'relative' }}>
          {!search && (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <SearchIcon color="secondary" />
            </div>
          )}
        </div>
        <Link
          href={getRedirectLink('indi')}
          className={
            router.asPath.includes('indi')
              ? styles.selected_link
              : styles.unselected_link
          }
        >
          Индивидуалки
        </Link>
        <Link
          href={getRedirectLink('normal')}
          className={
            router.asPath.includes('normal')
              ? styles.selected_link
              : styles.unselected_link
          }
        >
          Проститутки
        </Link>
        <Link
          href={getRedirectLink('elite')}
          className={
            router.asPath.includes('elite')
              ? styles.selected_link
              : styles.unselected_link
          }
        >
          Элитные
        </Link>
        <Link
          href={getRedirectLink('bdsm')}
          className={
            router.asPath.includes('bdsm')
              ? styles.selected_link
              : styles.unselected_link
          }
        >
          БДСМ
        </Link>
        <Link
          href={getRedirectLink('massage')}
          className={
            router.asPath.includes('massage')
              ? styles.selected_link
              : styles.unselected_link
          }
        >
          Массажистки
        </Link>
      </nav>
      <div className={styles.preview_container}>
        {isLoggedIn ? (
          <HeaderUserPreview />
        ) : (
          <div
            style={{
              display: 'flex',
              gap: '25px',
            }}
          >
            <Button
              fullWidth
              style={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                width: 'max-content',
              }}
              variant="contained"
              onClick={handleCreateNew}
              className={styles.button}
              classes={{
                root: styles.newButton,
              }}
            >
              <PersonAddAlt1Icon color="secondary" />
              Добавить Анкету
            </Button>
            <div
              style={{
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
              }}
              className={styles.login_link}
              onClick={() => handleToggleAuth()}
            >
              <Person2Icon />
              Войти
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderRedesigned;
