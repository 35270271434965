import { confirmRole, UserRole } from '@c/utils/role_mapper';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Avatar,
  Button,
  Badge,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { priceFormatter } from 'utils/girl.utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './styles.module.css';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsFeedPopover from './NotificationsFeedPopover';
import { theme } from 'theme/theme';
import AccountDrawer from './AccountDrawer';
import Typography2 from '@c/Typographies/Typography2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const HeaderUserPreview = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const user = useSelector((state: RootState) => state.user.userInfo);

  const notificationsMeta = useSelector(
    (state: RootState) => state.notifications.notificationsMeta,
  );
  const unread = notificationsMeta?.data?.new;
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  return (
    <div className={styles.container}>
      <NotificationsFeedPopover
        anchorEl={notifAnchorEl}
        onClose={() => setNotifAnchorEl(null)}
      />
      {confirmRole(UserRole.Girl) && (
        <a href="/profile/balance" className={styles.balance}>
          <AccountBalanceWalletIcon color="secondary" />
          <Typography2
            weight="400"
            size="m"
            color={theme.palette.secondary.main}
          >
            {priceFormatter(user.balance)}
          </Typography2>
        </a>
      )}
      {confirmRole(UserRole.Girl) && (
        <>
          {unread > 0 ? (
            <IconButton onClick={(e) => setNotifAnchorEl(e.target)}>
              <Badge color="primary" badgeContent={unread}>
                <NotificationsActiveIcon
                  color="secondary"
                  style={{ width: '32px', height: '32px' }}
                />
              </Badge>
            </IconButton>
          ) : (
            <IconButton onClick={(e) => setNotifAnchorEl(e.target)}>
              <Badge color="primary" badgeContent={unread}>
                <NotificationsIcon
                  color="secondary"
                  style={{ width: '32px', height: '32px' }}
                />
              </Badge>
            </IconButton>
          )}
        </>
      )}
      <AccountDrawer anchorEl={anchorEl} onClose={handleClose} />
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar
          sx={{ width: 36, height: 36, border: '2px solid white' }}
          src="/logo_circle.png"
        />
        <ExpandMoreIcon color="secondary" />
      </IconButton>
    </div>
  );
};

export default HeaderUserPreview;

// <MenuItem
//   onClick={() => router.push('/profile/account')}
//   className={styles.menuItem}
// >
//   <ListItemIcon>
//     <AccountCircleOutlinedIcon />
//   </ListItemIcon>
//   Мой аккаунт | {user?.preferred_username}
// </MenuItem>;
// {
//   confirmRole(UserRole.Girl) && (
//     <MenuItem onClick={handleClose} className={styles.menuItem}>
//       <ListItemIcon>
//         <CurrencyRubleOutlinedIcon />
//       </ListItemIcon>
//       Баланс: {priceFormatter(user?.balance)}
//     </MenuItem>
//   );
// }
// {
//   confirmRole(UserRole.Girl) && (
//     <MenuItem
//       onClick={() => router.push('/profile/managing')}
//       className={styles.menuItem}
//     >
//       <ListItemIcon>
//         <PeopleOutlineIcon />
//       </ListItemIcon>
//       Управление анкетами
//     </MenuItem>
//   );
// }
// {
//   /* <div className={styles.mobile_items}>
//             <Divider />
//             {settingsMenuItems.map((menuItem) => {
//               const isSelected = menuItem.id === section;
//               const iconColor = isSelected
//                 ? theme.palette.primary.main
//                 : theme.palette.textTransluent.main;
//               const icon = getIcon(menuItem.id, iconColor);
//               return (
//                 <MenuItem
//                   onClick={() => router.push(`/profile/${menuItem.id}`)}
//                   className={styles.menuItem}
//                 >
//                   <ListItemIcon>{getIcon(menuItem.id, iconColor)}</ListItemIcon>
//                   {menuItem.title}
//                 </MenuItem>
//               );
//             })}
//           </div> */
// }

// <MenuItem
//   onClick={() => {
//     router.push('/');
//     dispatch(logout());
//     handleClose();
//   }}
//   className={styles.menuItem}
// >
//   <ListItemIcon>
//     <ExitToAppOutlinedIcon />
//   </ListItemIcon>
//   Выйти
// </MenuItem>;
