import React, { useContext, useState } from 'react';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import Typography2 from '@c/Typographies/Typography2';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, CircularProgress, TextField } from '@mui/material';
import globalStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { theme } from 'theme/theme';
import { NotificationContext } from '@c/notification/NotificationProvider';
import { NotificationType } from 'shared/interfaces/notification.interface';
import { requestResetPassword, setNewPassword } from 'api/user.service';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useRouter } from 'next/router';

type ForgotPasswordStepProps = {
  onReset: () => void;
};

const NewPasswordStep: React.FC<ForgotPasswordStepProps> = ({ onReset }) => {
  const [show, setShow] = useState(false);
  const { handleSubmit, register, watch, formState } = useForm();
  const [loading, setLoading] = useState(false);
  const notify = useContext(NotificationContext);
  const { errors } = formState;
  const router = useRouter();
  const { reset_session } = router.query;
  const onSubmit = async (e) => {
    setLoading(true);
    try {
      await setNewPassword(e.password, reset_session);
      onReset();
      notify(NotificationType.Success, 'Ваш пароль обновлен');
    } catch {
      // notify(NotificationType.Error, 'Не получилось запросить сброс пароля');
    } finally {
      setLoading(false);
    }
    // onPasswordReset();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={globalStyles.form_container}
    >
      <Typography2 weight="600" size="xl" className={styles.heading}>
        Новый Пароль
      </Typography2>
      <TextField
        {...register('password', {
          required: 'Введите новый пароль',
        })}
        size="small"
        aria-label="password"
        placeholder="Новый пароль"
        fullWidth
        type={show ? 'text' : 'password'}
        InputProps={{
          startAdornment: (
            <KeyIcon className={styles.icon} color="textTransluent" />
          ),
          endAdornment: show ? (
            <VisibilityIcon
              className={styles.icon}
              color="textTransluent"
              style={{ cursor: 'pointer' }}
              onClick={() => setShow(!show)}
            />
          ) : (
            <VisibilityOffIcon
              className={styles.icon}
              color="textTransluent"
              style={{ cursor: 'pointer' }}
              onClick={() => setShow(!show)}
            />
          ),
        }}
      />
      <TextField
        {...register('confirmPassword', {
          required: 'Подтвердите новый пароль',
          validate: (val: string) => {
            if (watch('password') != val) {
              return 'Пароли не совпадают';
            }
          },
        })}
        size="small"
        aria-label="confirmPassword"
        placeholder="Подтвердите новый пароль"
        error={!!errors.confirmPassword}
        type={show ? 'text' : 'password'}
        helperText={errors?.confirmPassword?.message}
        fullWidth
      />

      <Button variant="contained" type="submit" fullWidth size="large">
        {loading ? (
          <CircularProgress size={26} color="secondary" />
        ) : (
          'Восстановить'
        )}
      </Button>
    </form>
  );
};

export default NewPasswordStep;
