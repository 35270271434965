import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import Link from 'next/link';
import React, { useState } from 'react';
import { useBaseUrl } from 'utils/user.utils';
import Image from 'next/image';
import styles from './styles.module.scss';
import Typography2 from '@c/Typographies/Typography2';
import { Search } from '@mui/icons-material';
import dynamic from 'next/dynamic';

const MobileSearchDrawer = dynamic(() => import('./MobileSearchDrawer'), {
  ssr: false,
});

type MobileDrawerProps = {
  open: boolean;
  onClose: () => void;
};

const MobileDrawer: React.FC<MobileDrawerProps> = ({ open, onClose }) => {
  const { getRedirectLink } = useBaseUrl();
  const [searchDrawer, setSearchDrawer] = useState(false);

  const toggleSearchDrawer = () => {
    setSearchDrawer(!searchDrawer);
  };

  const drawerSubmit = () => {
    onClose();
  };

  return (
    <Drawer
      anchor={'left'}
      open={open}
      onClose={onClose}
      sx={{ padding: 0, margin: 0 }}
    >
      <MobileSearchDrawer
        open={searchDrawer}
        onClose={() => {
          toggleSearchDrawer();
          drawerSubmit();
        }}
      />
      <List>
        <ListItem classes={{ root: styles.root }}>
          <Link href={'/'} className={styles.root_logo}>
            <Image
              style={{
                objectFit: 'contain',
              }}
              width={64}
              height={64}
              alt="Logo"
              src="/logo_small.svg"
            />
          </Link>
        </ListItem>
        <ListItem classes={{ root: styles.root }}>
          <ListItemButton
            onClick={toggleSearchDrawer}
            classes={{ root: styles.root_item }}
          >
            <Search color="lightPurple" style={{ marginRight: '4px' }} />
            <Typography2 size="l" weight="500">
              Поиск
            </Typography2>
          </ListItemButton>
        </ListItem>
        <ListItem classes={{ root: styles.root }}>
          <ListItemButton
            href={getRedirectLink('', ['indi'])}
            classes={{ root: styles.root_item }}
          >
            <Typography2 size="l" weight="500">
              Индивидуалки
            </Typography2>
          </ListItemButton>
        </ListItem>
        <ListItem classes={{ root: styles.root }}>
          <ListItemButton
            href={getRedirectLink('normal')}
            classes={{ root: styles.root_item }}
          >
            <Typography2 size="l" weight="500">
              Проститутки
            </Typography2>
          </ListItemButton>
        </ListItem>
        <ListItem classes={{ root: styles.root }}>
          <ListItemButton
            href={getRedirectLink('elite')}
            classes={{ root: styles.root_item }}
          >
            <Typography2 size="l" weight="500">
              Элитные
            </Typography2>
          </ListItemButton>
        </ListItem>
        <ListItem classes={{ root: styles.root }}>
          <ListItemButton
            href={getRedirectLink('bdsm')}
            classes={{ root: styles.root_item }}
          >
            <Typography2 size="l" weight="500">
              БДСМ
            </Typography2>
          </ListItemButton>
        </ListItem>
        <ListItem classes={{ root: styles.root }}>
          <ListItemButton
            href={getRedirectLink('massage')}
            classes={{ root: styles.root_item }}
          >
            <Typography2 size="l" weight="500">
              Массажистки
            </Typography2>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default MobileDrawer;
