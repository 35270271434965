import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import { theme } from 'theme/theme';
import { useMenu } from 'utils/user.utils';
import styles from './styles.module.scss';
import Person from '@mui/icons-material/Person';
import {
  Add,
  Help,
  Logout,
  ManageAccounts,
  Paid,
  PartyMode,
  Save,
  Support,
  AppShortcut,
} from '@mui/icons-material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Typography2 from '@c/Typographies/Typography2';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { priceFormatter } from 'utils/girl.utils';
import { useDispatch } from 'react-redux';
import useVerificationModal from '@c/Dialogs/VerificationModal/VerificationModal/VerificationModal';
import { confirmRole, UserRole } from '@c/utils/role_mapper';
import { makeLogout } from '../../../../utils/user.utils';

type AccountDrawerProps = {
  anchorEl: any;
  onClose: () => void;
};

const AccountDrawer: React.FC<AccountDrawerProps> = ({ anchorEl, onClose }) => {
  const menuItems = useMenu();
  const router = useRouter();
  const { section } = router.query;
  const getIcon = (id: string) => {
    const isSelected = id === section;
    const iconColor = isSelected ? 'primary' : 'textTransluent';
    switch (id) {
      case 'account':
        return <Person className={styles.icon} color={iconColor} />;
      case 'saved':
        return <Save className={styles.icon} color={iconColor} />;
      case 'balance':
        return <Paid className={styles.icon} color={iconColor} />;
      case 'managing':
        return <ManageAccounts className={styles.icon} color={iconColor} />;
      case 'messages':
        return <Person className={styles.icon} color={iconColor} />;
      case 'support':
        return <Help className={styles.icon} color={iconColor} />;
      case 'parties':
        return <PartyMode className={styles.icon} color={iconColor} />;
      case 'publishings':
        return <Person className={styles.icon} color={iconColor} />;
      case 'logout':
        return <Logout className={styles.icon} color={iconColor} />;
      case 'faq':
        return <QuestionAnswerIcon className={styles.icon} color={iconColor} />;
      case 'feed':
        return <Person className={styles.icon} color={iconColor} />;
      case 'app':
        return <AppShortcut className={styles.icon} color={iconColor} />;
      default:
        return <Person className={styles.icon} color={iconColor} />;
    }
  };
  const user = useSelector((state: RootState) => state.user.userInfo);
  const verification = useVerificationModal(
    'Вы уверены, что хотите выйти?',
    () => {
      makeLogout(router, dispatch);
    },
    'Выйти',
  );

  const getOverrideTitle = (id, menuItem) => {
    switch (id) {
      case 'account':
        return `Мой Аккаунт | ${user.preferred_username}`;
      case 'balance':
        return `Баланс | ${priceFormatter(user.balance)}`;
      default:
        return menuItem.title;
    }
  };
  const dispatch = useDispatch();
  const handleClick = async (menu) => {
    if (menu.id !== 'logout') {
      router.push(`/profile/${menu.id}`);
    } else {
      verification.setVerificationOpen(true);
    }
  };

  return (
    <>
      {verification.verificationModalComponent}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {confirmRole(UserRole.Girl) && (
          <MenuItem>
            <Button
              variant="contained"
              size="small"
              fullWidth
              className={styles.add_button}
              onClick={() => router.push('/profile/managing?create=true')}
            >
              <Add />
              Добавить Анкету
            </Button>
          </MenuItem>
        )}
        {confirmRole(UserRole.Girl) && <Divider />}
        <div>
          {menuItems.map((menu, index) => (
            <MenuItem key={index} onClick={() => handleClick(menu)}>
              <ListItemIcon color="primary">{getIcon(menu.id)}</ListItemIcon>
              <ListItemText>
                <Typography2
                  weight="500"
                  size="m"
                  color={theme.palette.textTransluent.main}
                >
                  {getOverrideTitle(menu.id, menu)}
                </Typography2>
              </ListItemText>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </>
  );
};

export default AccountDrawer;
