import React from 'react';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import styles from './styles.module.scss';
import Typography2 from '@c/Typographies/Typography2';
import { theme } from 'theme/theme';

type TypeInputProps = {
  value: string;
  options: [string, string];
  onChange: () => void;
};

const TypeInput: React.FC<TypeInputProps> = ({ value, onChange, options }) => {
  return (
    <div className={styles.buttons_container}>
      <div
        className={styles.type_button}
        style={{
          border:
            value === options[0]
              ? '1px solid ' + theme.palette.info.main
              : '1px solid ' + theme.palette.textTransluent.main,
        }}
        onClick={() => onChange(options[0])}
      >
        <MaleIcon
          color={value === options[0] ? 'info' : 'textTransluent'}
          style={{ width: '36px', height: '36px' }}
        />
        <Typography2
          weight="500"
          size="m"
          color={
            value === options[0]
              ? theme.palette.info.main
              : theme.palette.textTransluent.main
          }
        >
          Клиент
        </Typography2>
      </div>
      <div
        className={styles.type_button}
        style={{
          border:
            value === options[1]
              ? '1px solid ' + theme.palette.primary.main
              : '1px solid ' + theme.palette.textTransluent.main,
        }}
        onClick={() => onChange(options[1])}
      >
        <FemaleIcon
          style={{ width: '36px', height: '36px' }}
          color={value === options[1] ? 'primary' : 'textTransluent'}
        />
        <Typography2
          weight="500"
          size="m"
          color={
            value === options[1]
              ? theme.palette.primary.main
              : theme.palette.textTransluent.main
          }
        >
          Девушка
        </Typography2>
      </div>
    </div>
  );
};

export default TypeInput;
