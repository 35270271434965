import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

export enum UserRole {
  All = 'all',
  Client = 'client',
  Girl = 'girl',
  None = 'none',
}

export const confirmRole = (roleType: UserRole) => {
  const userRole = useSelector(
    (state: RootState) => state.user?.userInfo?.type,
  );
  if (roleType === UserRole.All) return true;
  return userRole === roleType;
};

export const confirmRoleStateLess = (
  roleTypeExpected: UserRole,
  roleTypeUser: UserRole,
) => {
  if (roleTypeExpected === UserRole.All) return true;
  return roleTypeExpected === roleTypeUser;
};
