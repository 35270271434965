import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TelegramIcon from '@a/../../assets/contacts/telegram.svg';
import InstagramIcon from '@a/../../assets/girlIcons/contacts/instagram.svg';
import TelephoneIcon from '@a/../../assets/girlIcons/contacts/phone.svg';
import styles from './contacts.module.css';
import { theme } from 'theme/theme';
import InputMask from 'react-input-mask';
import { IMaskMixin } from 'react-imask';
import { Controller } from 'react-hook-form';
import WhatsappIcon from '@mui/icons-material/WhatsApp';

export enum ContactsType {
  instagram = 'instagram',
  // viber = 'viber',
  whatsapp = 'whatsapp',
  telegram = 'telegram',
  channel = 'channel',
  telephone = 'telephone',
}

const getMask = (type) => {
  switch (type) {
    case ContactsType.instagram:
      return '';
    case ContactsType.telegram:
      return '';
    case ContactsType.channel:
      return '';
    default:
      return '+{7} (000) 000-00-00';
  }
};
export interface ContactsTemplate {
  type: string;
}

export interface ContactsUserInterface extends ContactsTemplate {
  value: string;
  icon: any;
}

export const contactsTemplate: ContactsUserInterface[] = [
  {
    type: ContactsType.telephone,
    value: '',
    icon: (selected: boolean) => (
      <TelephoneIcon
        width={30}
        height={30}
        fill={
          selected
            ? theme.palette.primary.main
            : theme.palette.textTransluent.main
        }
      />
    ),
  },
  {
    type: ContactsType.whatsapp,
    value: '',
    icon: (selected: boolean) => (
      <WhatsappIcon
        style={{
          color: selected
            ? theme.palette.primary.main
            : theme.palette.textTransluent.main,
          width: '30px',
          height: '30px',
        }}
      />
    ),
  },
  {
    type: ContactsType.telegram,
    value: '',
    icon: (selected: boolean) => (
      <TelegramIcon
        width={30}
        height={30}
        fill={
          selected
            ? theme.palette.primary.main
            : theme.palette.textTransluent.main
        }
      />
    ),
  },
  {
    type: ContactsType.instagram,
    value: '',
    icon: (selected: boolean) => (
      <InstagramIcon
        width={30}
        height={30}
        fill={
          selected
            ? theme.palette.primary.main
            : theme.palette.textTransluent.main
        }
      />
    ),
  },
  {
    type: ContactsType.channel,
    value: '',
    icon: (selected: boolean) => (
      <TelegramIcon
        width={30}
        height={30}
        fill={
          selected
            ? theme.palette.primary.main
            : theme.palette.textTransluent.main
        }
      />
    ),
  },
];

type ContactProps = {
  register: any;
  contacts: any;
  control: any;
  errors: any;
};

const IMaskPhoneInput = IMaskMixin(({ ...props }) => {
  return <TextField {...props} classes={{ root: styles.input_root }} />;
});

const ContactRow = ({ contact, control, errors }) => {
  const [innerState, setInnerState] = useState(contact.value);
  const name = String(contact.type);

  const contactValidate = (val: string, name: ContactsType) => {
    if (val?.length > 60) {
      return 'Указанный контакт слишком длинный';
    }

    switch (name) {
      case ContactsType.channel:
        return true;
      case ContactsType.whatsapp:
        return true;
      case ContactsType.telegram:
        return true;
      case ContactsType.telephone:
        if (!Boolean(val?.replace('+7 (', ''))) {
          return 'Укажите телефон';
        }
      default:
        return true;
    }
  };

  return (
    <div className={styles.contactRowContainer}>
      {contact.icon(Boolean(innerState || contact.value))}
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (val) => contactValidate(val, name),
        }}
        render={(props) => {
          return (
            <IMaskPhoneInput
              size="small"
              value={Boolean(innerState) ? innerState : contact.value}
              defaultValue={contact.value}
              onChange={(e: any) => {
                props.field.onChange(e.target.value);
                setInnerState(e.target.value);
              }}
              mask={getMask(contact.type)}
              inputProps={{ maxLength: 60 }}
              InputProps={{ maxLength: 60 }}
              error={!!errors?.[name]}
              helperText={errors?.[name]?.message}
              help
              label={
                contact.type.charAt(0).toUpperCase() + contact.type.slice(1)
              }
            />
          );
        }}
      />
    </div>
  );
};

const Contacts = ({ register, contacts, control, errors }: ContactProps) => {
  const [contactsState, setContactsState] =
    useState<ContactsUserInterface[]>(contactsTemplate);

  const handleMatchContacts = () => {
    const newState = contactsState.map((contact) => {
      const contactFound = contacts.find(
        (contactInner) => contactInner.type === contact.type,
      );
      if (contactFound?.value) {
        return { ...contact, value: contactFound.value };
      } else {
        return contact;
      }
    });
    setContactsState(newState);
  };

  useEffect(() => {
    handleMatchContacts();
  }, []);

  return (
    <div className={styles.contactsContainer}>
      {contactsState.map((contact: ContactsUserInterface) => {
        return (
          <ContactRow contact={contact} control={control} errors={errors} />
        );
      })}
    </div>
  );
};

export default Contacts;
