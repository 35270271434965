import React, { useContext, useState } from 'react';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import Typography2 from '@c/Typographies/Typography2';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, CircularProgress, TextField } from '@mui/material';
import globalStyles from '../styles.module.scss';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './styles.module.scss';
import { theme } from 'theme/theme';
import { NotificationContext } from '@c/notification/NotificationProvider';
import { NotificationType } from 'shared/interfaces/notification.interface';
import { loginUser } from 'api/user.service';
import { useDispatch } from 'react-redux';
import { getUserInfo, toggleAuthModal } from 'store/actions/user.actions';
import { useRouter } from 'next/router';

type LoginStepProps = {
  onRegister: () => void;
  onForgotPassword: () => void;
  onLogin: () => void;
};

const LoginStep: React.FC<LoginStepProps> = ({
  onRegister,
  onForgotPassword,
  onLogin,
}) => {
  const { handleSubmit, register } = useForm();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useContext(NotificationContext);
  const router = useRouter();
  const dispatch = useDispatch();
  const [triesLeft, setTriesLeft] = useState(4);
  const handleLoginSubmit = async (e) => {
    setLoading(true);
    try {
      await loginUser(e);
      await dispatch(getUserInfo());
      dispatch(toggleAuthModal());
      if (router.query?.redirect) {
        router.push(router?.query?.redirect);
      }
      onLogin();
    } catch (e) {
      if (e.response.status === 406) {
        notify(NotificationType.Error, 'Ваш аккаунт был отключен', 'Ошибка');
      } else if (e.response.status === 409) {
        notify(
          NotificationType.Error,
          'Ваш отключен на 1 час',
          'Слишком много попыток',
        );
      } else {
        notify(
          NotificationType.Error,
          'Не удалось войти. Возможно логин или пароль неправильные',
          'Ошибка',
        );
        if (triesLeft > 0) {
          setTriesLeft((prev) => prev - 1);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleLoginSubmit)}
      className={globalStyles.form_container}
    >
      <Typography2 weight="600" size="xl" className={styles.heading}>
        Войти
      </Typography2>
      <Typography2 weight="400" size="m" className={styles.heading}>
        Нет учётной записи?
        <a
          className={styles.link}
          style={{ color: theme.palette.primary.main }}
          onClick={onRegister}
        >
          Зарегистрироваться
        </a>
      </Typography2>
      <TextField
        {...register('username', {
          required: 'Пожалуйста, заполните имя пользователя или почту',
        })}
        size="small"
        aria-label="username"
        placeholder="Логин или email"
        fullWidth
        InputProps={{
          startAdornment: (
            <EmailIcon className={styles.icon} color="textTransluent" />
          ),
        }}
      />
      <TextField
        {...register('password', {
          required: 'Пожалуйста, заполните имя пользователя или почту',
        })}
        size="small"
        placeholder="Пароль"
        fullWidth
        aria-label="password"
        type={showPass ? 'text' : 'password'}
        InputProps={{
          startAdornment: (
            <KeyIcon className={styles.icon} color="textTransluent" />
          ),
          endAdornment: showPass ? (
            <VisibilityIcon
              className={styles.icon}
              color="textTransluent"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowPass(!showPass)}
            />
          ) : (
            <VisibilityOffIcon
              className={styles.icon}
              color="textTransluent"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowPass(!showPass)}
            />
          ),
        }}
      />
      <div className={styles.bottom_row}>
        <div className={styles.checkbox_container}>
          <Checkbox size="small" {...register('rememberMe')} />
          <Typography2 weight="400" size="m">
            Запомнить
          </Typography2>
        </div>
        <Typography2
          weight="400"
          size="m"
          color={theme.palette.primary.main}
          onClick={onForgotPassword}
        >
          <a className={styles.link}>Забыли пароль?</a>
        </Typography2>
      </div>

      <Button
        variant="contained"
        type="submit"
        disabled={loading}
        fullWidth
        size="large"
      >
        {loading ? <CircularProgress size={26} color="secondary" /> : 'Войти'}
      </Button>
      {triesLeft <= 3 && (
        <Typography2 size="s" color={theme.palette.primary.main}>
          У вас осталось {triesLeft}
        </Typography2>
      )}
    </form>
  );
};

export default LoginStep;
