import React, { createContext } from 'react';
import { useFilterSlug } from 'shared/search.utils';

type ContextValueType = {
  getBasePath: () => string;
  currentSlug: string[];
  currentQuery: string[];
  calculatedValues: {};
  filterState: {
    completeFilter: {};
    slugFilter: {};
    queryFilter: {};
    finish: boolean;
  };
  navigateWithParam: (paramType: string, paramValue: any) => void;
  applySearchQuery: (query: any) => void;
  resetSearch: () => void;
  getNavigateWithParamUrl: (paramType: string, paramValue: any) => string;
};

export const SlugContext = createContext<ContextValueType>();

type SlugContextProviderProps = {
  children: any;
  serverSlug: string[];
  serverQuery: string[];
  filterState: any;
  calculatedValues: any;
};

const SlugContextProvider: React.FC<SlugContextProviderProps> = ({
  children,
  serverSlug,
  serverQuery,
  filterState,
  calculatedValues,
}) => {
  // console.count('Slug context rerender');
  const slug = useFilterSlug(
    serverSlug,
    serverQuery,
    filterState,
    calculatedValues,
  );
  return <SlugContext.Provider value={slug}>{children}</SlugContext.Provider>;
};

export default SlugContextProvider;
