import { ContactsType } from '@c/Settings/SettingsManagePublishings/CreateNewPublishingModal/Step5/ContactForm';
import { confirmRoleStateLess, UserRole } from '@c/utils/role_mapper';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { latinize } from 'shared/search.utils';
import { RootState } from 'store/store';
import { checkHasVerified } from '../api/girl.service';
import { logoutGirlState } from '../store/actions/girls.actions';
import { logout, logoutLocal } from '../store/actions/user.actions';

export const isUserLoggedIn = () => {
  const user = useSelector((state: RootState) => state.user);
  if (process.browser) {
    const hasCookie = document?.cookie?.includes('session');
    return (
      (user?.userInfo?.preferred_username || user?.userInfo?.email) &&
      !user?.loginLoading &&
      hasCookie
    );
  } else {
    return false;
  }
};

export const settingsItems = [
  {
    title: 'Главная',
    id: 'account',
    permissions: UserRole.All,
  },
  {
    title: 'Баланс',
    id: 'balance',
    permissions: UserRole.Girl,
  },
  {
    title: 'Сохраненные Анкеты',
    id: 'saved',
    permissions: UserRole.Client,
  },
  {
    title: 'Управление Анкетами',
    id: 'managing',
    permissions: UserRole.Girl,
  },
  {
    title: 'Уведомления',
    id: 'notifications',
    permissions: UserRole.All,
  },
  {
    title: 'Поддержка',
    id: 'support',
    permissions: UserRole.Girl,
  },
  {
    title: 'Частые Вопросы',
    id: 'faq',
    permissions: UserRole.Girl,
  },
  {
    title: 'Приложение',
    id: 'app',
    permissions: UserRole.Girl,
  },
  // {
  //   title: 'Мои Вечеринки',
  //   id: 'parties',
  //   permissions: UserRole.All,
  // },
  // {
  //   title: 'Объявления',
  //   id: 'publishings',
  //   permissions: UserRole.All,
  // },
  {
    title: 'Выйти из Профиля',
    id: 'logout',
    permissions: UserRole.All,
  },
];

export const useMenu = () => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const { data: hasVerified } = useQuery('checkHasVerified', () => {
    return !userInfo.username ? false : checkHasVerified();
  });

  const menuItems = useMemo(() => {
    if (Boolean(userInfo)) {
      const filteredList = settingsItems?.filter((item) => {
        return confirmRoleStateLess(item.permissions, userInfo?.type);
      });
      if (userInfo?.type === UserRole.Girl) {
        if (Boolean(hasVerified)) return filteredList;
        else
          return filteredList.filter(
            (section) =>
              section.id !== 'balance' &&
              section.id !== 'notifications' &&
              section.id !== 'faq' &&
              section.id !== 'app',
          );
      }
      return filteredList;
    } else {
      return [];
    }
  }, [hasVerified]);
  return menuItems;
};

export const getMenuServer = (userRole: UserRole) => {
  const filteredList = settingsItems?.filter((item) => {
    return confirmRoleStateLess(item.permissions, userRole);
  });
  return filteredList;
};

export const getUserTown = () => {
  if (typeof window !== 'undefined') {
    const setTown = localStorage.getItem('city');
    if (setTown && setTown !== 'undefined' && setTown !== undefined) {
      return setTown;
    } else {
      return null;
    }
  }
};

export const setUserTown = (city) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('city', city);
  }
};

export const useBaseUrl = () => {
  const router = useRouter();
  const userTown = useMemo(() => {
    const town = getUserTown();
    if (town) {
      return latinize(town);
    } else {
      return '';
    }
  }, [router]);

  const getRedirectLink = (
    type: string | undefined = 'all',
    other: string[] | undefined = [],
    query?: string = '',
    overrideCity?: string = '',
  ) => {
    let finalSlug = `/girls`;

    if (type) {
      finalSlug += '/' + type;
    }

    if (userTown) {
      if (overrideCity) {
        finalSlug += '/' + latinize(overrideCity);
      } else {
        finalSlug += '/' + userTown;
      }
    }
    finalSlug += '/' + other.map((e) => latinize(e)).join('/');
    finalSlug += query;
    return finalSlug;
  };
  return { getRedirectLink };
};

export const constructSocialMediaLink = (type: ContactsType, link: string) => {
  const isUrl = link.includes('http');
  if (isUrl) {
    return link;
  }

  switch (type) {
    case ContactsType.whatsapp:
      return `https://api.whatsapp.com/send?phone=${link.replace(
        /[^0-9]/g,
        '',
      )}`;
    case ContactsType.telegram:
      return `https://t.me/${link}`;
    case ContactsType.instagram:
      return `https://www.instagram.com/${link}`;
    case ContactsType.channel:
      return `https://t.me/${link}`;
  }
  return link;
};

export const getServerSideAuthParams = (cookie: string) => {
  const identity = cookie.match(/(?<=identity=)[^;]+/g)?.join('');
  return identity;
};

// export const useServerSideAuthProtect = (req) => {
//   getServerSideAuthParams
// };

export const makeLogout = async (router, dispatch, skipRemote = false) => {
  return new Promise(async (resolve) => {
    await router.push('/');
    await dispatch(logoutGirlState());
    await dispatch(logoutLocal());
    if (!skipRemote) {
      await dispatch(logout(router));
    }
    localStorage.setItem('persist:nextjs', '');
    localStorage.removeItem('persist:nextjs');
    resolve(true);
  });
};
