import { modalBoxStyle } from '@c/Settings/LogoutModal';
import Typography2 from '@c/Typographies/Typography2';
import { Box, Modal } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toggleAuthModal } from 'store/actions/user.actions';
import { RootState } from 'store/store';
import { isUserLoggedIn } from 'utils/user.utils';
import EmailVerificationStep from './EmailVerificationStep';
import ForgotPasswordStep from './ForgotPasswordStep';
import LoginStep from './LoginStep';
import NewPasswordStep from './NewPasswordStep';
import RegisterStep from './RegisterStep';
import styles from './styles.module.scss';

enum AuthActionType {
  login = 'login',
  reset = 'reset',
  register = 'register',
  verify = 'verify',
  new_password = 'new_password',
}

const UserAuthModal = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const showAuthModal = useSelector(
    (state: RootState) => state.user.showAuthModal,
  );

  const [action, setAction] = useState(AuthActionType.login);
  const dispatch = useDispatch();
  const router = useRouter();
  const { reset_session } = router.query;

  const handleOnLogin = () => {
    setAction(AuthActionType.login);
  };

  const handleOnRegister = () => {
    setAction(AuthActionType.register);
  };

  const handleOnForgotPassword = () => {
    setAction(AuthActionType.reset);
  };

  const handleSuccessRegister = () => {
    setAction(AuthActionType.verify);
  };

  const handleOnEmailVerified = () => {
    setAction(AuthActionType.login);
  };

  const handleProcessResetLink = () => {
    if (reset_session) {
      if (!showAuthModal) {
        dispatch(toggleAuthModal());
      }

      setAction(AuthActionType.new_password);
    }
  };

  const handleForceEmailVerification = () => {
    setAction(AuthActionType.verify);
    if (!showAuthModal) {
      dispatch(toggleAuthModal());
    }
  };
  const emailVerified = useSelector(
    (state: RootState) => state.user.userInfo.email_verified,
  );
  useEffect(() => {
    if (isLoggedIn) if (!emailVerified) handleForceEmailVerification();
  }, [isLoggedIn, emailVerified]);

  useEffect(() => {
    handleProcessResetLink();
  }, [reset_session]);

  const activeComponent = useMemo(() => {
    switch (action) {
      case AuthActionType.login:
        return (
          <LoginStep
            onRegister={handleOnRegister}
            onForgotPassword={handleOnForgotPassword}
            onLogin={handleOnLogin}
          />
        );
      case AuthActionType.reset:
        return <ForgotPasswordStep onPasswordResetBack={handleOnLogin} />;
      case AuthActionType.register:
        return (
          <RegisterStep
            onRegister={handleSuccessRegister}
            onBack={handleOnLogin}
          />
        );
      case AuthActionType.verify:
        return (
          <EmailVerificationStep onEmailVerified={handleOnEmailVerified} />
        );
      case AuthActionType.new_password:
        return <NewPasswordStep onReset={handleOnLogin} />;
    }
  }, [action]);

  const handleCloseModal = () => {
    if (!isLoggedIn) {
      dispatch(toggleAuthModal());
    }

    if (isLoggedIn && emailVerified) dispatch(toggleAuthModal());
  };

  return (
    <Modal open={showAuthModal} onClose={handleCloseModal}>
      <Box sx={modalBoxStyle} className={styles.step_container}>
        {activeComponent}
      </Box>
    </Modal>
  );
};

export default UserAuthModal;
