import { InputAdornment, Button, Select, Autocomplete } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import SearchIcon from '@c/../assets/search.svg';
import React, { useEffect, useRef, useState } from 'react';
import styles from './search_field.module.css';
import { styled } from '@mui/material/styles';
import { axiosInstance } from 'api/axios.interceptors';
import SearchResultsPopover from './SearchResultsPopover';
import { GirlData } from '@c/types/girl.interface';
import Typography, { TypographyVariants } from '@c/Typographies/Typography';
import { priceFormatter } from 'utils/girl.utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useRouter } from 'next/router';

const TextField = styled(MuiTextField)(({}) => ({
  '& .MuiOutlinedInput-root': {
    flexDirection: 'row-reverse',
    width: '100%',
    '& fieldset': {
      border: 0,
    },
    '&:hover fieldset': {
      border: 0,
    },
    '&.Mui-focused fieldset': {
      border: 0,
    },
  },
}));

const SearchField = (attributes: any) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [timeout, setDebounceTimeout] = useState<any>(undefined);
  const [results, setResults] = useState([]);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    attributes?.setFocus ? attributes.setFocus(focus) : null;
  }, [focus]);

  const fetchResults = async () => {
    attributes?.setLoading ? attributes?.setLoading(true) : null;
    const { data } = await axiosInstance.post(
      'girl/text/search',
      {
        searchQuery,
      },
      { withCredentials: true },
    );
    if (data) {
      const final = data.map((result: GirlData) => result.id);
      setOptions(final);
      setResults(data);
      handleOpenSearchResults();
      attributes?.setLoading ? attributes?.setLoading(false) : null;
    }
  };

  useEffect(() => {
    if (searchQuery) {
      const newTimeout = setTimeout(() => {
        attributes?.onSearch ? attributes?.onSearch(searchQuery) : null;
        // fetchResults();
      }, 300);
      setDebounceTimeout(newTimeout);
    }
    clearTimeout(timeout);
  }, [searchQuery]);
  const [anchorEl, setAnchorEl] = useState(null);
  const fieldRef = useRef(null);

  const handleOpenSearchResults = () => {
    setAnchorEl(fieldRef.current);
  };

  const handleCloseSearchResults = () => {
    setAnchorEl(null);
  };

  const [options, setOptions] = useState([]);
  const router = useRouter();

  const handleKeyPress = (e) => {
    if (e.keyCode == 13) {
      attributes?.onSubmit ? attributes.onSubmit(searchQuery) : null;
    }
  };

  return (
    <div
      style={{
        width: focus ? '100%' : '40%',
        transition: 'all 0.1s ease-in-out',
      }}
    >
      <Autocomplete
        filterOptions={(option) => option}
        noOptionsText={''}
        options={[]}
        openOnFocus
        style={{
          transition: 'all 0.6s ease-in-out',
          overflow: 'hidden',
        }}
        freeSolo
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setResults([]);
          setOptions([]);
          setFocus(false);
        }}
        renderOption={(option) => {
          const girl = results.find(
            (girlLocal: GirlData) => girlLocal.id === option.key,
          );
          return (
            <div
              className={styles.girlSearchContainer}
              onClick={() => router.push('/girl/' + girl.id)}
            >
              <div
                className={styles.girlSearchImage}
                style={{
                  backgroundImage: `url(${
                    process.env.NEXT_PUBLIC_BACKEND_URL
                  }/${girl?.media?.filter((media) => type === 'image')?.[0]})`,
                  backgroundSize: 'contain',
                }}
              />
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '15px' }}
              >
                <div>
                  <Typography variant={TypographyVariants.Text18Medium}>
                    {girl?.name}
                  </Typography>
                  <Typography variant={TypographyVariants.Text16Medium}>
                    {priceFormatter(girl?.price1_in_day)} /{' '}
                    {priceFormatter(girl?.price2_in_day)}
                  </Typography>
                </div>
                <Typography
                  variant={TypographyVariants.Text16Medium}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <LocationOnIcon />
                  {girl?.locationCity}, {girl?.locationUnit}
                </Typography>
              </div>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            classes={{
              root: `${attributes?.rootClass} ${styles.root}`,
            }}
            size={attributes?.size ? attributes.size : 'small'}
            value={searchQuery}
            autoFocus
            placeholder="ID, телефонные номера, имена, услуги и все все все"
            InputProps={{ endAdornment: <SearchIcon />, ...params.InputProps }}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        )}
      />
    </div>
  );
};

export default SearchField;
